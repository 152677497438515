<template>
  <div>
    <b-card>
      <b-card-text>
        <b-row>
          <!-- <b-col cols="12" md="4">
            <b-form-group label="Country*">
              <v-select
                @input="getEventList"
                :clearable="false"
                v-model="form.country_id"
                label="country_name"
                value="country_id"
                :reduce="(country) => country.country_id"
                placeholder="Select Country"
                :options="countryListArray"
              />
            </b-form-group>
          </b-col> -->
          <b-col cols="12" md="4">
            <b-form-group label="Event*">
              <v-select
                :reduce="(event) => event.event_id"
                v-model="form.event_id"
                placeholder="Select Event"
                label="event_name"
                value="event_id"
                :options="eventListData"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Voucher Code*">
              <b-form-input
                v-model="form.voucher_code"
                id="input-default"
                placeholder="Voucher Code"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="2">
            <b-form-group label="Minimum Seats limit*">
              <b-form-input
                type="number"
                v-model="form.min_seats_required"
                id="input-default"
                placeholder="Minimum Seats limit"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="2">
            <b-form-group label="Max Seats limit*">
              <b-form-input
                type="number"
                v-model="form.max_seats_required"
                id="input-default"
                placeholder="Max Seats limit"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="2">
            <b-form-group label="Max Transaction Per User*">
              <b-form-input
                type="number"
                v-model="form.max_transaction_per_user"
                id="input-default"
                placeholder="Max Transaction Per User"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="2">
            <b-form-group label="Total Voucher*">
              <b-form-input
                type="number"
                v-model="form.total_available_voucher"
                id="input-default"
                placeholder="Total Voucher"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="2"
            ><b-form-group label="Discount Type">
              <v-select
                disabled
                :clearable="false"
                v-model="form.discount_type"
                label="label"
                value="value"
                :reduce="(discount) => discount.value"
                placeholder="Select Discount Type"
                :options="optionDiscounts"
              /> </b-form-group
          ></b-col>
          <b-col cols="12" md="2">
            <b-form-group label="Discount Value (%)*">
              <b-form-input
                type="number"
                v-model="form.voucher_discount_value"
                id="input-default"
                placeholder="Discount Value (%)"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Voucher Status">
              <b-form-checkbox v-model="form.voucher_is_active" switch>{{
                form.voucher_is_active == true ? "Active" : "Disabled"
              }}</b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12">
            <br />
            <small
              ><b
                >*Note : Voucher will be valid as per event start and event end
                date as provided in the event form.
              </b></small
            >
          </b-col>
          <b-col class="mt-3" md="6" offset-md="3">
            <b-button block variant="primary" @click="onSubmit"
              >Submit</b-button
            >
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import vSelect from "vue-select";

import {
  AddUpdateVoucherService,
  GetEventListService,
  GetCountryListService,
  GetVoucherListService,
} from "@/apiServices/MasterServices";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  data() {
    return {
      form: {
        voucher_id: null,
        org_id: null,
        country_id: null,
        event_id: null,
        voucher_code: null,
        min_seats_required: 1,
        max_seats_required: 1,
        max_transaction_per_user: 1,
        total_available_voucher: 0,
        discount_type: "percent",
        voucher_discount_value: 0,
        voucher_is_active: true,
      },
      countryListArray: [],
      eventListData: [],
      optionDiscounts: [{ label: "Percentage", value: "percent" }],
    };
  },
  components: {
    vSelect,
  },
  async beforeMount() {
    //this.getCountryList();
    this.getEventList();
    if (this.$route.query && this.$route.query.voucher_id) {
      this.form.voucher_id = this.$route.query.voucher_id;
      await this.getVoucherById(this.$route.query.voucher_id);
    }
  },
  methods: {
    async getEventList() {
      this.eventListData = [];
      try {
        const response = await GetEventListService({
          limit: 5000,
          // country_id: this.form.country_id,
        });
        if (response.data.status) {
          this.eventListData = response.data.Records;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getCountryList() {
      try {
        const response = await GetCountryListService({
          limit: 1000,
        });
        if (response.data.status) {
          this.countryListArray = response.data.Records.data;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onSubmit() {
      try {
        this.form.voucher_is_active =
          this.form.voucher_is_active == true ? "Y" : "N";
        const response = await AddUpdateVoucherService(this.form);
        if (response.data.status) {
          this.$router.push({
            name: "voucherlist",
          });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "primary",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getVoucherById(user_id) {
      try {
        const response = await GetVoucherListService({
          voucher_id: this.form.voucher_id ? this.form.voucher_id : null,
        });

        if (response.data.status) {
          if (response.data.Records && response.data.Records.data) {
            let voucherArray = response.data.Records.data[0];
            Object.keys(voucherArray).map((x) => {
              this.form[x] = voucherArray[x];
              if (x == "voucher_is_active") {
                this.form[x] = voucherArray[x] == "Y" ? true : false;
              }
            });
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
